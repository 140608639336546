import * as React from "react"
import { graphql } from "gatsby"
import Marquee from "react-fast-marquee"
import brandsPageHero from'../images/brands-hero.jpg'
import brandsPageFeatureImg from'../images/brands-feature-img.jpg'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import brandTheWallStreetJournal from '../images/brands/the-wall-street-journal.png'
import brandSteamy from '../images/brands/streamy-awards-linear.png'
import brandDigiday from '../images/brands/digiday-logo.png'
import brandFastCo from '../images/brands/fast-company.png'
import brandCynopsis from '../images/brands/cynopsis-media.png'

import Layout from "../components/layout"
import Seo from "../components/seo"

const BrandsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const talents = data.webiny.listTalent.data
  return (
    <Layout location={location} title={siteTitle} className="bg-black">
      
      <Seo title="Brands" />

      <div className="">
        <div className="h-120 md:h-[51.25rem] overflow-hidden relative lg:bg-none bg-cover bg-center" style={{ backgroundImage: `url(${brandsPageHero})` }}>
          <img src={brandsPageHero} className="hidden lg:inline-block w-full z-0" />
          <div className="px-5 bottom-5 lg:p-0 absolute lg:bottom-10 lg:left-5 z-30">
            <div className="w-full lg:pl-2">
              <h1 itemProp="headline" className="knockoutFont text-6xl md:text-8xl uppercase">
              Our brand partners return
              </h1>
            </div>
          </div>
          <div className="h-72 lg:h-96 bg-gradient-to-t from-black absolute w-full bottom-0 z-20" />
        </div>
      </div>

      <Marquee
        autoFill 
        pauseOnHover
        gradient={false} 
        className="w-full uppercase bg-gray-300 text-black TitlingGothicFBWideFont py-2 border-b-2 border-t-2 border-black">
          <div className="p-2">&nbsp;&nbsp;CONTENT. TALENT. EXPERIENTIAL.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//</div>
      </Marquee>

      <div className="bg-neon-green pb-20">

        <div className="mx-auto px-5 lg:px-0">
          
          <div className="text-black">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <did className="pt-10 md:py-10 lg:py-20 lg:max-w-2xl lg:pl-20">
                <h2 className="tracking-tight font-medium text-8xl md:text-7xl lg:text-8xl xl:text-12xl md:w-screen lg:max-w-4xl knockoutFont uppercase text-center md:text-left pb-5">GWB IS A COLLECTIVE</h2>
                <div className="">
                  <p className="text-center md:text-left text-xl leading-6 lg:text-2xl lg:leading-7 mb-5 lg:mb-10 col-span-1 md:col-span-2 md:pr-20">Our brand partners trust us to translate their needs into cultural moments that deepen their connection to creators and the massive digital communities accessed.</p>
                  <a href="mailto:hello@gamingwhileblack.show" className="text-white rounded-2xl text-lg md:text-xl lg:text-2xl xl:text-3xl leading-7 text-center py-5 after:content-link after:inline-block after:ml-5 after:w-6 lg:after:w-7 xl:after:w-10 bg-black after:float-right TitlingGothicFBWideFont flex items-center justify-center hover:scale-105 ease-in-out duration-500">PARTNER WITH US</a>
                </div>
              </did>
              <div className="md:py-10 2xl:py-0">
                <img src={brandsPageFeatureImg} className="w-full" />
              </div>
            </div>
          </div>



          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-10 pb-20">
            <div className="">
              <div className="h-80 w-full bg-white  text-black border border-black p-7 relative hover:shadow-solid transition-shadow">
                <h2 className="knockoutFont text-xl uppercase">CONTENT</h2>
                <p className="knockoutFont text-6xl uppercase">Creative Strategy<br />Development<br /> Production</p>
              </div>
            </div>
            <div className="">
              <div className="h-80 w-full bg-white  text-black border border-black p-7 relative hover:shadow-solid transition-shadow">
                <h2 className="knockoutFont text-xl uppercase">TALENT</h2>
                <p className="knockoutFont text-6xl uppercase">Campaign Curation<br /> Management<br /> Co-Creation</p>
              </div>
            </div>
            <div className="">
              <div className="h-80 w-full bg-white  text-black border border-black p-7 relative hover:shadow-solid transition-shadow">
                <h2 className="knockoutFont text-xl uppercase">EXPERIENTIAL</h2>
                <p className="knockoutFont text-6xl uppercase">Community<br /> Events<br /> Watch Parties<br /> Virtual</p>
              </div>
            </div>
          </div> */}

        </div>


        <Accordion allowZeroExpanded className="text-black border-black border-t-2 mt-5 md:mt-0">
          <AccordionItem className="border-b-2 border-black">
            <AccordionItemHeading>
              <AccordionItemState>
                  {({ expanded }) => (expanded ? 
                  <AccordionItemButton />
                  : 
                  <AccordionItemButton className="ProximaNova font-bold text-4xl lg:text-5xl uppercase p-5">
                  CONTENT
                  <div className="content-accordionCloseBlack w-7 lg:w-10 float-right rotate-45" />
                  </AccordionItemButton>)}
              </AccordionItemState>
            </AccordionItemHeading>
            <AccordionItemPanel className="">
              <div className="">
                <div className="">
                  <h2 className="ProximaNova font-bold text-4xl lg:text-5xl uppercase p-5">
                  CONTENT
                  <AccordionItemButton className="content-accordionCloseBlack w-7 lg:w-11 float-right" />
                  </h2>
                  <div className="col-span-8 pb-5 px-5">
                    <ul className="ProximaNova text-4xl lg:text-5xl">
                      <li className="py-3">– Creative Strategy</li>
                      <li className="py-3">– Development</li>
                      <li className="py-3">– Production</li>
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="border-b-2 border-black">
            <AccordionItemHeading>
              <AccordionItemState>
                  {({ expanded }) => (expanded ? 
                  <AccordionItemButton />
                  : 
                  <AccordionItemButton className="ProximaNova font-bold text-4xl lg:text-5xl uppercase p-5">
                  TALENT
                  <div className="content-accordionCloseBlack w-7 lg:w-10 float-right rotate-45" />
                  </AccordionItemButton>)}
              </AccordionItemState>
            </AccordionItemHeading>
            <AccordionItemPanel className="">
              <div className="">
                <div className="">
                  <h2 className="ProximaNova font-bold text-4xl lg:text-5xl uppercase p-5">
                  TALENT
                  <AccordionItemButton className="content-accordionCloseBlack w-7 lg:w-11 float-right" />
                  </h2>
                  <div className="col-span-8 pb-5 px-5">
                    <ul className="ProximaNova text-4xl lg:text-5xl">
                      <li className="py-3">– Campaign Curation</li>
                      <li className="py-3">– Management</li>
                      <li className="py-3">– Co-Creation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="">
            <AccordionItemHeading>
              <AccordionItemState>
                  {({ expanded }) => (expanded ? 
                  <AccordionItemButton />
                  : 
                  <AccordionItemButton className="ProximaNova font-bold text-4xl lg:text-5xl uppercase p-5">
                  EXPERIENTIAL
                  <div className="content-accordionCloseBlack w-7 lg:w-10 float-right rotate-45" />
                  </AccordionItemButton>)}
              </AccordionItemState>
            </AccordionItemHeading>
            <AccordionItemPanel className="">
              <div className="">
                <div className="">
                  <h2 className="ProximaNova font-bold text-4xl lg:text-5xl uppercase p-5">
                  EXPERIENTIAL
                  <AccordionItemButton className="content-accordionCloseBlack w-7 lg:w-11 float-right" />
                  </h2>
                  <div className="col-span-8 pb-5 px-5">
                    <ul className="ProximaNova text-4xl lg:text-5xl">
                      <li className="py-3">– Community</li>
                      <li className="py-3">– Events</li>
                      <li className="py-3">– Watch Parties</li>
                    </ul>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>


        <hr className="border border-black border-b" />

        <div className="px-5 py-10 md:pt-20 text-black">
            <h2 className="tracking-tight font-medium text-7xl md:text-6xl lg:text-7xl xl:text-8xl knockoutFont uppercase text-center pb-0">OUR WORK HAS BEEN RECOGNIZED By</h2>
        </div>

        <div className="flex py-5">
          <Marquee
            autoFill 
            pauseOnHover
            direction="left"
          >
            <img src={brandTheWallStreetJournal} className="w-60 mx-10" />
            <img src={brandSteamy} className="w-60 mx-10" />
            <img src={brandDigiday} className="w-40 mx-10" />
            <img src={brandFastCo} className="w-60 mx-10" />
            <img src={brandCynopsis} className="w-60 mx-10" />
          </Marquee>
        </div>

      </div>
      

    </Layout>
  )
}

export default BrandsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
    }
  }
`
